import { styled } from '@mui/system'
import MenuList from '@mui/material/MenuList'
import Button from '../../molecules/button'

const Root = styled(MenuList)`
	margin-top: auto;
	margin-bottom: 36px;
	padding: 0;
	padding-top: 83px;
	.MuiMenuItem-root {
		border: 0;
		padding: 0;
		min-height: 30px;
		font-size: ${({ theme }) => theme.typography.pxToRem(12)};
		&:hover {
			background: transparent;
		}
		.MuiSvgIcon-root {
			font-size: ${({ theme }) => theme.typography.pxToRem(18)};
		}
		.MuiLink-root {
			display: flex;
			align-items: center;
			&:hover {
				color: ${({ theme }) => theme.palette.grey.main};
			}
			> button,
			> svg {
				margin-right: 10px;
			}
		}
	}
`

const ButtonIcon = styled(Button)`
	padding: 0;
	.MuiSvgIcon-root {
		color: ${({ theme }) => theme.palette.primary.main};
		font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	}
`

export { ButtonIcon, Root }
