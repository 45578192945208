/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const HeartIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M19.19 21.75a.53.53 0 01-.27-.08l-6.08-3.91a1.55 1.55 0 00-1.68 0l-6.08 3.91c-.15.1-.35.1-.51.02a.509.509 0 01-.26-.44V4.81c0-1.41 1.15-2.56 2.56-2.56h10.28c1.41 0 2.55 1.15 2.55 2.56v16.44c0 .18-.1.35-.26.44-.07.04-.16.06-.24.06zM6.86 3.25c-.86 0-1.56.7-1.56 1.56v15.53l5.31-3.42c.84-.54 1.92-.54 2.76 0l5.31 3.42V4.81c0-.86-.7-1.56-1.55-1.56H6.86z" />
	</svg>
))
HeartIconSvg.displayName = 'HeartIconSvg'

const HeartIcon = forwardRef((props, ref) => <Icon component={HeartIconSvg} ref={ref} {...props} />)
HeartIcon.displayName = 'HeartIcon'

HeartIcon.defaultProps = {
	...Icon.defaultProps,
}
HeartIcon.propTypes = {
	...Icon.propTypes,
}

export default HeartIcon
export { HeartIconSvg }
